<template>
  <div :class="[blur ? 'blur-images' : '']" :id="`outer-${index}`" class="container-fluid blur-wrapper m-0 p-0"
    v-if="loaded">
    <div v-if="mobile" :class="[index === 0 ? 'pt-3' : 'pt-2']" class="p-3 pb-1 d-flex justify-content-between">
      <div :class="[mobileInfo ? 'strikethrough' : '']" class="home-btn">
        {{ current.primary.project_title[0].text }}
      </div>
      <div v-if="$route.name === ' / Left-Field'">
        ( {{ current.primary.date }} )
      </div>
      <div v-if="!mobileInfo && $route.name !== ' / Left-Field'" @click="openInfo">
        ( Info )
      </div>
      <div v-if="mobileInfo" @click="mobileInfo = false">( Close )</div>
    </div>
    <div ref="inner" :id="`inner-${index}`" class="project-content d-flex flex-row mx-0 mb-0">
      <div :class="[info || mobileInfo ? 'info-container-open' : '']"
        class="info-container ps-3 d-flex align-items-between">
        <div :style="[info || mobileInfo ? 'opacity:1' : '']"
          class="info-wrapper d-flex justify-content-between justify-content-md-end flex-column">
          <div class="works-font d-block d-md-none">
            <div class="works-font d-flex">
              <prismic-rich-text style="line-height: 16px; text-transform: uppercase"
                :field="current.primary.client"></prismic-rich-text>
            </div>
            <div>YEAR: {{ current.primary.date }}</div>
          </div>

          <prismic-rich-text :field="current.primary.project_description"
            style="text-transform: uppercase"></prismic-rich-text>
          <div class="works-font d-md-flex d-none">
            <prismic-rich-text style="line-height: 16px; text-transform: uppercase" class="client-container"
              :field="current.primary.client"></prismic-rich-text>
          </div>
          <prismic-rich-text class="works-font pb-0" style="text-transform: uppercase"
            :field="current.primary.specifications"></prismic-rich-text>
        </div>
      </div>
      <div v-for="(image, index) in current.items" :key="index" class="col-auto pe-2 image-wrapper position-relative">
        <video v-if="image.video.url" class="lazyload" playsinline preload="none" muted="" data-autoplay="" loop
          :src="image.video.url"></video>
        <LazyImage v-else :index="index" :url="image.image.url" :height="image.image.dimensions.height"
          :width="image.image.dimensions.width" />
      </div>
    </div>
  </div>
</template>
<script setup>
import "lazysizes";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import LazyImage from "@/components/ui/LazyImage.vue";
import { loadImages } from "@/assets/imageLazy";
import { ref, defineProps, toRefs, watch, onMounted } from "vue";

const props = defineProps(["loaded", "info", "current", "mobile", "index", "blur"]);
const { loaded, info, current, mobile } = toRefs(props);

const mobileInfo = ref(false);

function openInfo() {
  mobileInfo.value = true;

  document
    .getElementById(`inner-${props.index}`)
    .scroll({ top: 0, left: 0, behavior: "smooth" });

  setTimeout(() => {
    document
      .getElementById(`outer-${props.index}`)
      .scrollIntoView({ block: "start" });
  }, 800);
}
watch(info, () => {
  if (info.value) {
    const projectNav = document.getElementById("project-nav");

    document
      .querySelector(".project-content")
      .scroll({ top: 0, left: 0, behavior: "smooth" });

    setTimeout(() => {
      projectNav.scrollIntoView({ block: "start" });
    }, 800);
  }
});
const inner = ref(null)

onMounted(() => {

  loadImages();
  // console.log(mobile.value)
  if (!mobile.value) {
    if (inner.value) {
     
       
      inner.value.addEventListener("wheel", (e) => {
        if ((window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight) {
          if (e.deltaY > 0) {
            inner.value.scrollLeft += e.deltaY
            e.preventDefault();
          }
          else {
            if (inner.value.scrollLeft > 0) {
              inner.value.scrollLeft -= Math.abs(e.deltaY)
              e.preventDefault();
            }
          }
        }
      });
    }
  }
});
</script>

<style scoped lang="scss">
.blur-images {
  filter:blur(25px)
}
.strikethrough {
  text-decoration: line-through;
}

.info-container-open {
  width: 90vw !important;
  height: auto !important;
}

.info-container {
  transition: width ease 500ms;
  width: 0;

  height: 0;
}

.info-wrapper {
  min-height: 100%;
  line-height: 15.56px;
  min-width: 90vw;
  overflow-x: scroll;
  padding-right: 2rem;
  opacity: 0;
  transition: opacity ease 500ms;
  display: block;
}

.projects-container::-webkit-scrollbar {
  display: none;
}

.project-content img {
  min-width: 100%;
  height: 65vh;
  width: auto;
  z-index: 1;
  box-shadow: none;
}

video {
  min-width: 100%;
  width: auto;
  z-index: 1;
  height: 65vh;
}

@media only screen and (min-width: 768px) {
  .info-wrapper {
    min-width: calc(50vw - 2rem);
  }

  .info-container-open {
    width: 50vw;
    height: auto;
  }

  .project-content img {
    height: calc(100vh - 100px);
  }

  video {
    height: calc(100vh - 100px);
  }
}

.project-content {
  width: 100%;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  overflow-y: hidden;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  display: inline-block;
  max-height: 90vh;
  position: relative;
  height: 100%;
}

.projects-container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  overflow-y: hidden;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}

.image-wrapper {
  transform-origin: top left;
  position: relative;
  line-height: 0;
}
.blur-wrapper {
    transition: filter 500ms ease;
}


.project-content::-webkit-scrollbar {
  display: none;
}

video::-webkit-media-controls-start-playback-button {
  display: none;
}
</style>
