<template>
  <LandingPage :basecolor="landingColour" />
  <NavBar
    :basecolor="landingColour"
    :abouttext="aboutData"
    :email="emailAddress"
    :arena="arena"
    :linkedin="linkedin"
  />

  <router-view v-slot="slotProps">
    <transition name="route" mode="out-in">
      <component :is="slotProps.Component"></component>
    </transition>
  </router-view>

  <PageFooter
    :arena="arena"
    :linkedin="linkedin"
    :email="emailAddress"
    v-if="!onHome"
  />
</template>
<script>
import LandingPage from "@/components/ui/LandingPage.vue";
import NavBar from "@/components/ui/NavBar.vue";
import PageFooter from "@/components/ui/PageFooter.vue";

export default {
  components: {
    LandingPage,
    NavBar,
    PageFooter,
  },
  data() {
    return {
      onHome: true,
      showLanding: true,
      aboutData: {},
      arena: [],
      linkedin: [],
      emailAddress: [],
      colours: [
        "#216649",
        "#212C66",
        "#82290C",
        "#623B81",
        "#60361E",
        "#1F1F1B",
        "#182E24",
        "#B39884",
      ],
    };
  },
  methods: {
    getContent() {
      this.$prismic.client.getSingle("bio__navigation").then((response) => {
        this.aboutData = {
          partOne: response.data.biography,
          partTwo: response.data.address,
        };
        this.emailAddress = response.data.email_address;
        this.arena = response.data.arena;
        this.linkedin = response.data.linkedin;
      });
    },
    showFooter() {
      const path = this.$route.path;

      if (path.length <= 1) {
        this.onHome = true;
      } else {
        this.onHome = false;
      }
    },
  },
  created() {
    this.getContent();
    this.showFooter();
  },
  computed: {
    landingColour() {
      const index = Math.floor(Math.random() * this.colours.length);
      return this.colours[index];
    },
  },
  watch: {
    $route() {
      this.showFooter();
    },
  },
};
</script>


<style lang="scss">
@font-face {
  font-family: "Suisse Intl";
  src: url("~@/assets/fonts/SuisseIntl-Regular.woff2") format("woff2"),
    url("~@/assets/fonts/SuisseIntl-Regular.woff") format("woff");
}

@font-face {
  font-family: "Suisse Works";
  src: url("~@/assets/fonts/SuisseWorks-Regular.woff2") format("woff2"),
    url("~@/assets/fonts/SuisseWorks-Regular.woff") format("woff");
}
html {
  height: 100%;
}
.scheme {
  background: v-bind(landingColour) !important;
}

#app {
  font-family: Suisse Intl, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1e1e1e;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

html,
body #app {
  background: white;
  height: 100%;
}
body {
  min-height: 100%;
}
.works-font,
.label {
  font-family: Suisse Works, "Times New Roman", Times, serif;
  font-weight: 400;
}
.works-font p {
  padding-bottom: 0;
  margin-bottom: 0;
}

.home-btn {
  text-transform: uppercase;
}

a {
  color: #1e1e1e;
  text-decoration: none;

  &.router-link-exact-active {
    color: #1e1e1e;
    text-decoration: none;
  }
}

a:hover {
    color: #1e1e1e;
}

@media only screen and (min-width: 768px) {
a:hover {
   color: rgb(166, 166, 166);
}
}

.info-wrapper a:hover {
color: rgb(166, 166, 166);
}

// .not-gray:hover {
//   color: black;
// }
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active {
  transition: all 400ms ease-in;
}

.fade-leave-active {
  transition: all 400ms ease-out;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 0.6;
}

.route-enter-from,
.route-leave-to {
  opacity: 0;
}

.route-enter-active {
  transition: all 300ms ease-in;
}

.route-leave-active {
  transition: all 300ms ease-out;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
}

*::-webkit-scrollbar {
  display: none;
}

p a {
  text-decoration: none;
  color: #1e1e1e;
}

a:hover {
  cursor: pointer;
  color: #1e1e1e;
}
.client-desktop p {
  margin-bottom: 0;
}
</style>
