<template>
  <div id="project-nav" class="p-3">
    <div class="row d-flex align-items-start justify-content-between">
      <div class="col-md-4 col-12 order-1 order-md-2">
        <span @mouseover="previewProjectEnter(project, index)" @mouseleave="previewProjectLeave" class="index-nav"
          @click="changeProject(project)" v-for="(project, index) in projects" :key="index">{{ index + 1 }}
          <span v-if="index + 1 < projects.length">/ </span></span>
      </div>

      <div v-if="loaded && !hoverPreview" class="col-md-6 col-12 home-btn order-2 order-md-1">
        {{ title }} /
        {{ date }}
      </div>
      <div v-if="loaded && hoverPreview" class="col-md-6 col-12 home-btn order-2 order-md-1">
        {{ currentTitle }} /
        {{ currentDate }}
      </div>

      <div @click="toggleInfo" :class="[showInfo ? 'strikethrough' : '']"
        class="col-1 p-0 d-none d-md-block info-btn order-md-3">
        Information
      </div>
      <div class="col-12"></div>
    </div>
    <div class="preview-img h-100">
      <div class="container-fluid d-flex h-100 justify-content-center align-items-center">
        <div class="image" v-show="index === currentHover" v-for="(p, index) in projects" :key="'project-' + index">
          <img v-if="p.items[0].image.url" class="img-fluid" :src="p.items[0].image.url + '?&w=400&q=35'" alt="">
          <video v-else class="lazyload img-fluid" playsinline preload="none" muted=""
            data-autoplay="" loop :src="p.items[0].video.url"></video>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  props: ["loaded", "title", "date", "projects"],
  data() {
    return {
      showInfo: false,
      currentTitle: "",
      currentDate: "",
      hoverPreview: false,
      currentHover: {}
    };
  },
  methods: {
    changeProject(e) {
      this.$emit("hovering", false)
      this.currentHover = null
      this.showInfo = false;
      this.$emit("info", this.showInfo);
      this.$emit("changeproject", e);
    },
    toggleInfo() {
      this.showInfo = !this.showInfo;
      this.$emit("info", this.showInfo);
    },
    previewProjectEnter(el, i) {
      document.body.style.overflow = 'hidden'
      this.$emit("hovering", true)
      this.currentHover = i
      this.currentTitle = el.primary.project_title[0].text;
      this.currentDate = el.primary.date;
      this.hoverPreview = true;
    },
    previewProjectLeave() {
      document.body.style.overflow = 'auto'
      this.$emit("hovering", false)
      this.currentHover = null
      this.hoverPreview = false;
    },
  }
};
</script>

<style scoped>
.preview-img {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  user-select: none;
  pointer-events: none;
}

.preview-img .image {
  max-width: 20%;
  opacity: 0.7;
}


.index-nav {
  user-select: none;
  opacity: 0.2;
}

.info-btn {
  user-select: none;
}

.info-btn:hover {
  cursor: pointer;
  color: #1e1e1e;
}

.index-nav:hover {
  cursor: pointer;
  color: #1e1e1e;
}

.strikethrough {
  text-decoration: line-through;
}

@media only screen and (min-width: 768px) {
  .index-nav {
    opacity: 1;
  }

  .index-nav:hover {
    color: rgb(166, 166, 166);
  }

  .info-btn:hover {
    color: rgb(166, 166, 166);
  }
}
</style>